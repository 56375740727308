<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        src="heroleads-logo.png"
        alt="Heroleads Logo"
      />
      <h2 class="brand-text text-primary ml-1 mt-2">
        HeroAIX
      </h2>
    </b-link>
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          You are not authorized! 🔐
        </h2>
        <p class="mb-2">
          You don’t have permission to access this page.
        </p>
        <div class="mb-2">
          Automatically redirects you to home page in {{ countdownSeconds }} seconds.
        </div>
        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ name: 'campaigns-list'}"
        >
          Back to Home
        </b-button>
        <b-img
          fluid
          :src="imageUrl"
          alt="Not authorized"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store/index'
import {
  BLink,
  BImg,
  BButton,
} from 'bootstrap-vue'
import VueRouter from 'vue-router'

const { isNavigationFailure, NavigationFailureType } = VueRouter

export default {
  components: {
    BLink,
    BImg,
    BButton,
  },
  data() {
    return {
      image: require('@/assets/images/pages/not-authorized.svg'),
      countdownSeconds: 5,
    }
  },
  computed: {
    imageUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.image = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.image
      }
      return this.image
    },
  },
  mounted() {
    this.startCountdown()
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  methods: {
    startCountdown() {
      if (this.countdownSeconds > 1) {
        this.timer = setTimeout(() => {
          this.countdownSeconds -= 1
          this.startCountdown()
        }, 1000)
      } else {
        this.$router.push({ name: 'campaigns-list' }).catch(failure => {
          if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
            this.$router.push({ name: 'heroai-logout' })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
